<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>{{ modalTitle }}</h3>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
        </div>
        <div class="modal-body-container pb-0 mb-0 border-0">
          <div class="form-group">
            <label for="categoryNameField">Category Name</label>
            <input type="text" v-model="formData.categoryName" id="categoryNameField" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" @click="$emit('close')">Cancel</button>
        <button class="btn bg-green" @click="saving">
          <span
            v-if="ui.saving"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrorsList from "../../../../components/form/ValidationErrorsList";
export default {
  name: "ActionMarketingCategory",
  components: { ValidationErrorsList },
  props: {
    category: {}
  },
  data() {
    return {
      formData: {
        id: 0,
        categoryName: ""
      },
      ui: {
        saving: false,
        form: {
          loading: false,
          isSuccess: false,
          isError: false,
          error: '',
          errors: [],
        }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.category ? "Edit Category" : "Add Category";
    }
  },
  beforeMount() {
    if (this.category) {
      this.getData();
    }
  },
  methods: {
    saving() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('title', this.formData.categoryName)
      const url = this.category ? `/api/v1/marketing-resource/${this.formData.id}/edit` : '/api/v1/marketing-resource/create';
      this.$http.post(url, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit('action-category')
      })
      .catch((err) => {
        this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
        let validation = this.getErrorsFromResponse(err.response)
        this.ui.form.isError = true
        this.ui.form.error = validation.error
        this.ui.form.errors = validation.errors
      }).finally(() => this.ui.saving = false)
    },
    getData() {
      this.formData.id = this.category.id;
      this.formData.categoryName = this.category.title;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-block {
  height: auto !important;
  border-radius: 8px !important;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .warning {
    padding: 2rem !important;
    display: flex;

    &-img {
      width: 130px;
    }

    &-message {
      text-align: center;
      color: #4c4c4c;
      margin: auto;
    }
  }
}
</style>
