<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>{{ modalTitle }}</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mb-0 border-0 pb-0">
          <div class="row mt-3" v-if="ui.form.isError">
            <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
          </div>
          <div class="form-group mb-3">
            <label>Vendor logo</label>
            <file-field-new :multiple="false" :files="formData.files" @change="uploadFile" field-id="field-upload" v-if="!ui.isFile" />
            <div class="row" v-if="vendor && ui.isFile">
              <div class="col-8">
                <div class="marketing-section-item--logo">
                  <img :src="agentPhoto(formData.logo)" height="60" alt />
                </div>
              </div>
              <div class="col-4 d-flex justify-content-center">
                <button class="btn btn-outline-dark" @click="removeLogo">Remove</button>
              </div>
            </div>
            <span class="my-2">Formats: png,jpg</span>
          </div>
          <div class="form-group mb-3">
            <label for="vendorField">Vendor</label>
            <input
              type="text"
              v-model="formData.vendor"
              id="vendorField"
              placeholder="e.g. Brand Name"
            />
          </div>
          <div class="form-group full-width mb-3">
            <label for="descField">Description</label>
            <textarea placeholder="Optional" id="descField" v-model="formData.desc" />
          </div>
          <div class="form-group form-check mb-3">
            <input id="isMailtoLink" class="form-check-input" type="checkbox" v-model="formData.isMailtoLink">
            <label class="form-check-label cursor-pointer" for="isMailtoLink">Mailto link?</label>
          </div>
          <div class="form-group full-width mb-3">
            <label for="vendorField">{{ formData.isMailtoLink ? 'Email address' : 'Resource Link' }}</label>
            <input id="vendorField"
                   :type="formData.isMailtoLink ? 'email' : 'url'"
                   :placeholder="formData.isMailtoLink ? 'mailto:' : 'https://'"
                   v-model="formData.resourceLink"/>
          </div>
          <div class="form-group full-width mb-3" v-if="formData.isMailtoLink">
            <label for="vendorField">Email Subject (optional)</label>
            <input type="text" v-model="formData.mailtoSubject" id="vendorField"/>
          </div>
          <div class="form-group form-check mb-3">
            <input id="openNewTab" class="form-check-input" type="checkbox" v-model="formData.openNewTab">
            <label class="form-check-label cursor-pointer" for="openNewTab">Open in new tab</label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-outline-dark" v-if="vendor" @click="deleteItem" :disabled="ui.isDelete">
           <span
              v-if="ui.isDelete"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>Delete</span>
          </button>
        <button class="btn bg-green" @click.prevent="saving" :disabled="ui.saving">
          <span
            v-if="ui.saving"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Save Changes</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileFieldNew from "../../../../components/FileFieldNew.vue";
import ValidationErrorsList from "../../../../components/form/ValidationErrorsList";
export default {
  components: { FileFieldNew, ValidationErrorsList },
  name: "ActionVendorModal",
  props: {
    vendor: {},
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formData: {
        id: 0,
        logo: null,
        vendor: "",
        desc: "",
        resourceLink: "",
        files: [],
        openNewTab: true,
        isMailtoLink: false,
        mailtoSubject: ''
      },
      ui: {
        saving: false,
        isDelete: false,
        isFile: false,
        form: {
          isError: false,
          error: '',
          errors: []
        }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.vendor ? "Edit Vendor" : "Add Vendor";
    }
  },
  beforeMount() {
    if (this.vendor) {
      this.getData()
    }
  },
  methods: {
    saving() {
      this.ui.form = {
        isError: false,
        error: '',
        errors: []
      };

      var file = this.formData.files[0] ?? null;
      if (!file && !this.formData.logo ) {
        this.ui.form.error = 'Please upload a image';
        this.ui.form.isError = true;
        return;
      }
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('vendor', this.formData.vendor)
      formData.append('description', this.formData.desc)
      formData.append('resourceLink', this.formData.resourceLink)
      formData.append('openNewTab', this.formData.openNewTab)
      formData.append('file', file)
      formData.append('isUpdate', this.vendor ? 1 : 0)
      formData.append('isMailtoLink', this.formData.isMailtoLink)
      formData.append('mailtoSubject', this.formData.mailtoSubject)

      const url = `api/v1/marketing-resource/${this.categoryId}/item/${this.vendor ? this.vendor.id + '/edit' : 'store'}`
      this.$http.post(url, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit('edit-vendor')
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.isError = true
          this.ui.form.error = validation.error
          this.ui.form.errors = validation.errors
        }).finally(() => this.ui.saving = false)
    },
    deleteItem() {
      this.ui.isDelete = true;
      if (confirm("Are you sure?")) {
        this.$http.delete(`api/v1/marketing-resource/item/${this.vendor.id}/delete`)
          .then((res) => {
            this.alertSuccess(res.data.message);
            this.$emit('edit-vendor')
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.error = validation.error
        }).finally(() => this.ui.isDelete = false)
      }
    },
    uploadFile(files) {
      this.formData.files = files;
      this.ui.form.isError = false;
      if ((this.formData.files[0]['type']!=='image/jpeg' && this.formData.files[0]['type']!=='image/png' )) {
        this.ui.form.error = 'Invalid image format';
        this.ui.form.isError = true;
        this.formData.files = null;
      }
    },
    removeLogo() {
      this.ui.isFile = false;
      this.formData.logo = null;
    },
    getData() {
      this.formData.id = this.vendor.id;
      this.formData.vendor = this.vendor.vendor;
      this.formData.desc = this.vendor.description;
      this.formData.resourceLink = this.vendor.resource_link;
      this.formData.logo = this.vendor.logo;
      this.formData.openNewTab = this.vendor.open_new_tab;
      this.formData.isMailtoLink = this.vendor.is_mailto_link;
      this.formData.mailtoSubject = this.vendor.mailto_subject;
      this.ui.isFile = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .scanned-doc {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 300px;
    border: 1px dashed #808080;
    border-radius: 4px;
    &-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
    }
    span {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
}

.full-width {
  input {
    width: 100%;
  }
}
.marketing-section-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &--title {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 6px;
  }
  &--logo {
    display: flex;
    align-items: center;
    min-height: 60px;
    background: url("~@/assets/images/pattern-diagonal-lines.png");
    border: 1px solid #cccccc;

    img {
      max-width: 100%;
    }
  }
}
.form-check-input {
  max-width: 20px;
}
</style>
