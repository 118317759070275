<template>
  <page-spinner v-if="ui.loading" />
  <div v-else>
    <div class="marketing-section" v-for="marketingResource in marketingResources" :key="marketingResource.id">
      <div class="marketing-section--header">
        <h2>
          {{marketingResource.title}}
          <span>#{{marketingResource.sequence+1}}</span>
        </h2>
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-dark" @click="editCategory(marketingResource)">Edit</button>
          <button class="btn btn-outline-dark ms-2 btn-move" @click="changeSequence(marketingResource.id, 'up')">
            <img src="@/assets/icons/icon-arrow arrow-up.svg" alt />
          </button>
          <button class="btn btn-outline-dark ms-2 btn-move" @click="changeSequence(marketingResource.id, 'down')">
            <img src="@/assets/icons/icon-arrow arrow-down.svg" alt />
          </button>
        </div>
      </div>
      <div class="marketing-section-body">
        <div class="marketing-section-items">
          <marketing-section-item 
            :items="marketingResource.items" 
            :category-id="marketingResource.id" 
            :categories="marketingResources" 
            @edit-vendor="editVendor"
            @action-category="getMarketingResource" />
        </div>
      </div>
      <div class="marketing-section--footer">
        <button class="btn btn-outline-dark" @click="editVendor(null, marketingResource.id)">Add Vendor</button>
      </div>
    </div>
    <button class="btn btn-outline-dark add-section" @click="ui.categoryModal = true">Add Section</button>
    <vue-page-transition name="fade">
      <action-vendor-modal
        v-if="ui.vendorModal"
        @close="closeVendorModal"
        @edit-vendor="vendorClose"
        :vendor="selectedVendor"
        :category-id="selectedCategoryId"
      />
    </vue-page-transition>
    <vue-page-transition name="fade">
      <action-marketing-category
        v-if="ui.categoryModal"
        @close="closeCategoryModal()"
        @action-category="actionCategoryClose"
        :category="selectedCategory"
      />
    </vue-page-transition>
  </div>
</template>

<script>
import ActionMarketingCategory from "../modals/ActionMarketingCategory.vue";
import ActionVendorModal from "../modals/ActionVendorModal.vue";
import MarketingSectionItem from "./MarketingSectionItem.vue";
import PageSpinner from "../../../../components/pageSpinner.vue";

export default {
  name: "MarketingSection",
  components: {
    MarketingSectionItem,
    ActionVendorModal,
    ActionMarketingCategory,
    PageSpinner
  },
  data() {
    return {
      ui: {
        vendorModal: false,
        categoryModal: false,
        loading: false,
      },
      marketingResources: [],
      selectedVendor: null,
      selectedCategory: null,
      selectedCategoryId:0
    };
  },
  methods: {
    closeVendorModal() {
      (this.ui.vendorModal = false), (this.selectedVendor = null);
    },
    closeCategoryModal() {
      (this.ui.categoryModal = false), (this.selectedCategory = null);
    },
    editCategory(category) {
      (this.ui.categoryModal = true), (this.selectedCategory = category);
    },
    editVendor(vendor, categoryId) {
      (this.ui.vendorModal = true), (this.selectedVendor = vendor), (this.selectedCategoryId = categoryId);
    },
    getMarketingResource() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/marketing-resource/`)
        .then((res) => {
          this.marketingResources = res.data.data;
          this.ui.loading = false;
      })
      .catch(() => {
        this.ui.loading = false;
      })
    },
    actionCategoryClose() {
      this.getMarketingResource();
      this.closeCategoryModal();
    },
    vendorClose() {
      this.getMarketingResource();
      this.closeVendorModal();
    },
    changeSequence(id, action) {
      this.ui.loading = true;
      let formData = new FormData();
      formData.append('action', action);
      this.$http.post(`/api/v1/marketing-resource/${id}/update-sequence`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.getMarketingResource();
      })
      .catch(() => {
        this.ui.loading = false;
      })
    }
  },
  beforeMount() {
    this.getMarketingResource();
  }
};
</script>

<style lang="scss" scoped>
.marketing-section {
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;

    h2 {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 0;
      span {
        font-size: 16px;
        color: rgba($color: #000000, $alpha: 0.7);
        font-weight: normal;
      }
    }
  }
  &--notice {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 30px;
  }
  &-items {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  &--footer {
    padding: 16px 20px;
  }
}
.add-section {
  width: 100%;
  height: 31px;
  border: 1px solid #cccccc;
  border-radius: 8px;
}
.btn-move {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
</style>
