<template>
  <page-spinner v-if="ui.loading" />
  <span v-else>
    <div class="marketing-section-item" v-if="items.length > 0">
      <div class="row w-100 m-auto border py-3" v-for="(item, index) in items" :key="item.id">
        <div class="col-9">
          <div class="row">
            <div class="col gx-5">#{{index+1}}</div>
            <div class="col-md-3 ps-0">
              <div class="marketing-section-item--logo">
                <img :src="agentPhoto(item.logo)" height="60" alt />
              </div>
            </div>
            <div class="col-md-8">
              <h2 class="marketing-section-item--title">{{item.vendor}}</h2>
              <p class="marketing-section-item--desc">
                {{item.description}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-3 gx-5">
          <div class="d-flex justify-content-end">
            <button class="btn btn-outline-dark mt-1" @click="$emit('edit-vendor', item, categoryId)">Edit</button>
            <multiselect 
              @select="moveItem($event, item.id)"
              id="category"
              :allow-empty="false"
              :close-on-select="true"
              :options="categories.filter(({id}) => id !== categoryId)"
              :searchable="true"
              :show-labels="false" 
              track-by="value" 
              label="title"
              class="mx-3" 
              placeholder="Move">
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="videos-list-item text-center mt-4" v-else>
        <span class="no-result">Vendor not found</span>
    </div>
  </span>
</template>

<script>
import PageSpinner from "../../../../components/pageSpinner.vue";
import Multiselect from 'vue-multiselect'
export default {
  name: "MarketingSectionItem",
  components: {
    PageSpinner,
    Multiselect
  },
  props: {
    items: {
      type: Array,
      default: Array
    },
    categories: {
      type: Array,
      default: Array
    },
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ui: {
        loading: false,
      },
      selectedCategoryId: ''
    };
  },
  methods: {
    moveItem(event, itemId) {
      this.ui.loading = true;
      let formData = new FormData()
      formData.append('categoryId', event.id)
      this.$http.post(`/api/v1/marketing-resource/item/${itemId}/move`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit('action-category')
      })
      .catch((err) => {
        this.alertError(err.response.data ? err.response.data : err.response.statusText)
      }).finally(() => this.ui.loading = false)
    }
  }
};
</script>

<style lang="scss" scoped>
.marketing-section-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &--title {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 6px;
  }
  &--desc {
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
  &--logo {
    display: flex;
    align-items: center;
    min-height: 60px;
    background: url("~@/assets/images/pattern-diagonal-lines.png");
    border: 1px solid #cccccc;

    img {
      max-width: 100%;
    }
  }
}
</style>
