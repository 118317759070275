<template>
  <div class="marketing">
    <base-page-header :back-link="backLink" :title="headerTitle" />
    <base-page-content>
      <template #content>
        <div class="marketing-sections">
          <page-spinner v-if="ui.loading" />
          <div v-else>
            <div class="marketing-section disabled">
              <div class="marketing-section--header">
                <h2>
                  Media
                  <span>#1</span>
                </h2>
                <div class="marketing-section---notice">Section managed by Developers</div>
              </div>
            </div>
            <marketing-section />
          </div>
        </div>
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageContent from "../../../components/BasePageContent.vue";
import BasePageHeader from "../../../components/BasePageHeader.vue";
import PageSpinner from "../../../components/pageSpinner.vue";
import MarketingSection from "./includes/MarketingSection.vue";
export default {
  components: {
    BasePageHeader,
    BasePageContent,
    MarketingSection,
    PageSpinner
  },
  name: "MarketingAdmin",
  data() {
    return {
      ui: {
        loading: false,
      }
    }
  },
  computed: {
    backLink() {
      return "marketing_resource";
    },
    headerTitle() {
      return "Marketing Resources (admin)";
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => { this.ui.loading = false }, 1000)
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  padding: 0 30px;
  &::v-deep {
    .row {
      margin: auto 0;
      width: 100%;
    }
  }
}

.page-content {
  margin-top: 19.5px;
}

.marketing {
  &-section {
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    &.disabled {
      background-color: #f6f6f6;
    }
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 20px;

      h2 {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 0;
        span {
          font-size: 16px;
          color: rgba($color: #000000, $alpha: 0.7);
          font-weight: normal;
        }
      }
    }
    &--notice {
      color: rgba($color: #000000, $alpha: 0.7);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
}
</style>
